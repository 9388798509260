import styles from "./UserChatMessage.module.css";
import {Container,Row,Col} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.css';
import { useConst } from '@fluentui/react-hooks';
import { useEffect, useMemo, useRef, useState } from "react";
interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {

    // Function to format a date in a specific locale and time zone
    const formatDate = (date: Date, timeZone: string = 'Asia/Kolkata'): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone,
    };
    return new Intl.DateTimeFormat('en-IN', options).format(date);
    };

    const UserreceivedTime: Date = useMemo(() => new Date(), []);


    return (
        <div className={styles.container}>
            
                <div className={styles.message}>
                
                        <div className={styles.UserTextQuery}>
                            {message}
                </div>
                
                <div className={styles.UserTextTimeStamp}>
                    {formatDate(UserreceivedTime)}
                </div>
        
            
        </div>
        </div>
    );
};
