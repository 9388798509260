import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Dropdown, IDropdownOption } from "@fluentui/react";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";

import { chatApi, update, RetrievalMode, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { Any } from "@react-spring/web";
import { mergeStyleSets, FocusTrapZone, Layer, Overlay, Popup } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import CarousolCard from "../../components/carousolCard/carousolCard";

import { Container, Row, Col } from 'react-bootstrap';
import CompanyLogos from "../../components/CompanyLogos";
import { getOrCreateUserId } from '../../../utility';

const popupStyles = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.3)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
    },
    content: {
        background: 'white',
        left: '50%',
        maxWidth: '400px',
        padding: '20px',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

const Chat = () => {
    // useEffect(() => {
    //     const JaiChatBotUserId = getOrCreateUserId();
    //     console.log('User ID:', JaiChatBotUserId);
    // }, []);
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const [comment, setComment] = useState<string>("");
    const likeVal = useRef<{ val: number, index: number }>({ val: 0, index: 0 });
    const [commentError, setCommentError] = useState<string>("");

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Vectors);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(true);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    // useEffect(() => {
    //     localStorage.setItem("username", "localhost")
    //     fetch("/.auth/me")
    //         .then(function (response) {
    //             return response.json().then((body: any) => {
    //                 try {
    //                     let username = body[0]["user_id"];
    //                     localStorage.setItem("username", username)
    //                 } catch (error) {
    //                     console.log("error:", error)
    //                 }
    //             });
    //         })
    // }, [])

    // const onLikeDislike = async (val: number, index: number) => {
    //     likeVal.current["val"] = val;
    //     likeVal.current["index"] = index;
    //     if (val == -1) {
    //         showPopup()
    //     } else {
    //         setAnswers((ans: [user: string, response: AskResponse][]) => {
    //             let copyAns: [user: string, response: AskResponse][] = JSON.parse(JSON.stringify(ans));
    //             copyAns[index][1].isLike = val
    //             return copyAns;
    //         })
    //         await update({ isLike: val, id: answers[index][1]["id"], comment: "" })
    //     }
    // }
    const onLikeDislike = async (val: number, index: number) => {
        // Update the current like value and index
        likeVal.current = { val, index };

        // Update the answers state to reflect the new like/dislike status
        setAnswers((ans) => {
            const updatedAnswers = [...ans];
            updatedAnswers[index][1].isLike = val;
            return updatedAnswers;
        });

        // Make the API call to update the like/dislike status
        // await update({ isLike: val, id: answers[index][1].id, comment: "" });
    };


    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                shouldStream: shouldStream,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    retrievalMode: retrievalMode,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions,
                    
                    
                },
                username: localStorage.getItem("username")
            };

            const response = await chatApi(request);
            console.log('res: ',  response.body)
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                let answer: string = '';
                let askResponse: AskResponse = {} as AskResponse;
                for await (const event of readNDJSONStream(response.body)) {
                    // console.log("event:", event);
                    if (event["data_points"]) {
                        askResponse = event;
                    } else if (event["choices"] && event["choices"][0]["delta"]) {
                        answer += event["choices"][0]["delta"]["content"] || "";
                        let latestResponse: AskResponse = { ...askResponse, answer: answer };
                        if (event["choices"][0]["finish_reason"] === "stop") {
                            latestResponse["id"] = event["choices"][0]["inserted_id"];
                            latestResponse["isLike"] = 0
                        }
                        setIsLoading(false);
                        
                        setAnswers([...answers, [question, latestResponse]]);
                    }
                }
            } else {
                const parsedResponse: AskResponse = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse]]);
            }
        } catch (e) {
            console.log("error:", e)
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            console.log("onShowCitation IF PASSED")
            setActiveAnalysisPanelTab(undefined);
        } else {
           
            const backendUrl = import.meta.env.VITE_APP_API_ENDPOINT; 

            const url = `${backendUrl}/get_citation/${citation}`;
            window.open(url, "_blank")
            
			
            // setActiveCitation(url);
            // setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const saveComment = async () => {
        console.log("comment:", comment)
        if (comment) {
            hidePopup()
            const val = likeVal.current["val"];
            const index = likeVal.current["index"];
            setAnswers((ans: [user: string, response: AskResponse][]) => {
                let copyAns: [user: string, response: AskResponse][] = JSON.parse(JSON.stringify(ans));
                copyAns[index][1].isLike = val
                return copyAns;
            })
            await update({ isLike: val, id: answers[index][1]["id"], comment: comment })
            setComment("")
        } else {
            // commentError.current = "Comment is required"
            setCommentError("Comment is required")
            console.log("commentError:", commentError)
        }
    }

    const chatEndRef = useRef<HTMLDivElement>(null);
    
    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [answers]);


    return (
        
        <div className={styles.container}>

            {/* {isPopupVisible && (
                <Layer>
                    <Popup
                        className={popupStyles.root}
                        role="dialog"
                        aria-modal="true"
                        onDismiss={hidePopup}
                    >
                        <Overlay onClick={hidePopup} />
                        <FocusTrapZone>
                            <div className={popupStyles.content}>
                                <h2 className={styles.commentHeader}>Comment</h2>
                                <div className={styles.commentForm}>
                                    <TextField label="" multiline rows={5} cols={150} value={comment} onChange={(e: any) => {
                                        setComment(e.target.value)
                                        if (e.target.value) {
                                            // commentError.current = ""
                                            setCommentError("")
                                        } else {
                                            // commentError.current = "Comment is required"
                                            setCommentError("Comment is required")
                                        }

                                    }} errorMessage={commentError} />
                                </div>
                                <div>
                                    <DefaultButton onClick={saveComment} primary>Submit</DefaultButton>
                                    <DefaultButton onClick={() => { setComment(""); hidePopup() }} className={styles.closePopup}>Close </DefaultButton>
                                </div>
                            </div>
                        </FocusTrapZone>
                    </Popup>
                </Layer>
            )}
             */}
            
            <div className={styles.chatRoot}>
                
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        
                        <div className={styles.chatEmptyState}>


                            <div className={styles.classFier}>
                    

                    
                                <div className={styles.staticlogojaiicon}>
                                    <img src="../../../public/Ask-Jai-Icon.svg" alt="Ask JAI" width="25px;" />
                                </div>
                                <div className={styles.StaticGroup}>
                                            <div className={styles.Templatecontainer}>

                                    <div className={styles.TemplateQuery}>
                                        <span className={styles.TemplateQuery1}>Hi there, How may I help your ?</span>
                                        {/* <span className={styles.TemplateQuery2}>How may I help you?</span> */}
                                        
                                    </div>
                                    
                                            </div>

                                            <div className={styles.Triangle}>
                                                <img src="../../../public/triangle.svg" alt="Ask JAI" width="25px;" height="35px;"></img>
                                            </div>
                                
                                            <div className={styles.Templatecontainer2}>

                                                <div className={styles.TemplateQuery}>
                                                    <span className={styles.TemplateQuery3}>You can ask me TMT related queries.</span>
                                                    <span className={styles.TemplateQuery4}>Eg. Loyalty programs, Prices, Sales offices, Distributors & Dealers for TMT in your location.</span>
                                                </div>   
                                                
                                            </div>
                                    
                                </div>
                        
                            </div>
                           
                        </div>
                    ) : (
                        
                            
                            <div className={styles.chatMessageStream}>
                                
                                <div className={styles.chatEmptyState}>
                                    <div className={styles.classFier}>
                

                
                                        <div className={styles.staticlogojaiicon}>
                                            <img src="../../../public/Ask-Jai-Icon.svg" alt="Ask JAI" width="25px;" />
                                        </div>

                                        <div className={styles.StaticGroup}>
                                            <div className={styles.Templatecontainer3}>

                                                <div className={styles.TemplateQuery}>
                                                    <span className={styles.TemplateQuery1}>Hi there, How may I help your ?</span>
                                                    {/* <span className={styles.TemplateQuery2}>How may I help you?</span> */}
                                                    
                                                </div>
                                                        
                                                
                                            </div>

                                            <div className={styles.Triangle2}>
                                                <img src="../../../public/triangle.svg" alt="Ask JAI" width="25px;" height="35px;"></img>
                                            </div>
                                            
                                            <div className={styles.Templatecontainer2}>

                                                <div className={styles.TemplateQuery}>
                                                    <span className={styles.TemplateQuery3}>You can ask me TMT related queries.</span>
                                                    <span className={styles.TemplateQuery4}>Eg. Loyalty programs, Prices, Sales offices, Distributors & Dealers for TMT in your location.</span>
                                                </div>   
                                                
                                            </div>
                                        </div>
                    
                                    </div>
                                            
                                    </div>
                                
                             {answers.map((answer, index) => (
                                <div key={index}>
                                     <UserChatMessage message={answer[0]} />
                                     
                                    <div className={styles.chatMessageGpt}>
                                        
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            onLikeDislikeClicked={(val) => {
                                                onLikeDislike(val, index)                   
                                            }}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            <div ref={chatEndRef} />
                  
                            <div ref={chatMessageStreamEnd} />
                            
                            
                            
                        </div>
                        
                    )
                    }

                    
                </div>

                
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many search results:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                    <Dropdown
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                            { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                            { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label="Stream chat completion responses"
                        onChange={onShouldStreamChange}
                    />
                </Panel>
                    
            </div>

            <div className={styles.chatInput} style={activeAnalysisPanelTab ? { width: "50%" } : {}}>
                        
                            
                        <QuestionInput
                            
                            
                                clearOnSend
                                
                                placeholder="How may i help you? (e.g. What is the price of TMT 8 mm in Delhi? )"
                                
                                disabled={isLoading}
                                onSend={question => makeApiRequest(question.trim())}
                            />
                            
            </div>
            
        </div>
        
    );
};

export default Chat;
