import { useEffect, useMemo, useRef, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import {Container,Row,Col} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.css';

import { AskResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { ThumbDislike20Filled, ThumbDislike20Regular, ThumbLike20Filled, ThumbLike20Regular } from "@fluentui/react-icons";
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useConst } from '@fluentui/react-hooks';
import { animated, useSpring } from "@react-spring/web";


const facts = [
    "Do you know Jindal Panther is India’s 1st and largest producer of FE550D TMT Rebar",
    "Do you know Jindal Panther TMT rebars are produced at our own Integrated Steel Plant.",
    "Jindal Panther is proud to be a partner in India’s growth journey",
    "Asly Sariye ki pehchan Jindal Panther ka Nishan",
    "Desh ki Shaan har Nirman ki Jan, Jindal Panther",
    "Do you know Jindal Panther is a top choice for Contractors, Architects and Consumers"
];



const getRandomFact = () => {
    const randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex];
};


interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onLikeDislikeClicked: (val: number) => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    onLikeDislikeClicked,
    showFollowupQuestions
}: Props) => {
    const selectedCitation = useRef<any>(null);
    // const [selectedCitation, setSelectedCitation] = useState<any>(null);
    
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);
    
    const menuProps = useConst<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        shouldFocusOnContainer: true,
        items: [
            {
                key: 'Open Page', text: 'Open Page', onClick: () => {
                    if(selectedCitation.current){
                        const page = getCitationFilePath(selectedCitation.current[0])
                        onCitationClicked(page)
                    }
                }
            },
            {
                key: 'Open File', text: 'Open File', onClick: () => {
                    if(selectedCitation.current){
                        window.open(selectedCitation.current[1], "__blank")
                    }
                }
            },
        ],
    }));
    // const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
     let sanitizedAnswerHtml;

    if (parsedAnswer.answerHtml.includes('https://')) {
        sanitizedAnswerHtml = parsedAnswer.answerHtml;
    } else {
        sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    }
    
    const citationClick = () => {
        console.log("citationClick")
    }
    
    const [currentFact, setCurrentFact] = useState(getRandomFact());

    useEffect(() => {
        const factInterval = setInterval(() => {
            setCurrentFact(getRandomFact());
        }, 4000); // Update fact every 2 seconds

        return () => clearInterval(factInterval); // Cleanup interval on component unmount
    }, []);

    
    // Function to format a date in a specific locale and time zone
    const formatDate = (date: Date, timeZone: string = 'Asia/Kolkata'): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone,
    };
    return new Intl.DateTimeFormat('en-IN', options).format(date);
    };

    const receivedTime: Date = useMemo(() => new Date(), []);
    


    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">

            

            <div className={styles.classFier}>
                

                
                    <div className={styles.logojaiicon}>
                        <img src="../../../public/Ask-Jai-Icon.svg" alt="Ask JAI" width="25px;" />
                    </div>
                    <Stack.Item grow>
                    {sanitizedAnswerHtml ? (
                        <div className={styles.classFier2}>
                            
                            <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                            
                            <div className={styles.citationMan}>
                                    {!!parsedAnswer.citations.length && (
                                        <Stack.Item>
                                            <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                                <span className={styles.citationLearnMore}>Citations:</span>
                                                
                                                {parsedAnswer.citations.map((x, i) => {

                                                    return (
                                                        
                                                        <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(x)}>
                                                            {`${++i}. ${x}`}
                                                        </a>
                                                        );
                                                })}
                                            
                                            </Stack>
                                        </Stack.Item>
                                        )
                                    }
                            </div>
                            <Stack.Item>
                                <Stack horizontal horizontalAlign="space-between">
                                    {/* <AnswerIcon /> */}
                                <div className={styles.AnswerFooterArea}>
                                        <div className={styles.LikeDislikeHands}>
                                            
                                            {
                                                answer.isLike === 0 ? <>
                                                    <IconButton style={{ color: "black" }} onClick={() => onLikeDislikeClicked(1)} title="Like">
                                                        <ThumbLike20Regular />
                                                    </IconButton>
                                                    <IconButton style={{ color: "black" }} onClick={() => onLikeDislikeClicked(-1)} title="Dislike">
                                                        <ThumbDislike20Regular />
                                                    </IconButton>
                                                </> : ""
                                            }
                                            {
                                                answer.isLike === 1 ?
                                                    <>
                                                        <IconButton style={{ color: "black" }}>
                                                            <ThumbLike20Filled />
                                                        </IconButton>
                                                        <IconButton style={{ color: "black" }} onClick={() => onLikeDislikeClicked(-1)} title="dislike">
                                                            <ThumbDislike20Regular />
                                                        </IconButton>
                                                    </>
                                                    : ""
                                            }
                                            {
                                                answer.isLike === -1 ? <>
                                                    <IconButton style={{ color: "black" }} onClick={() => onLikeDislikeClicked(1)} >
                                                        <ThumbLike20Regular title="Like" />
                                                    </IconButton>
                                                    <IconButton style={{ color: "black" }}>
                                                        <ThumbDislike20Filled />
                                                    </IconButton>
                                                </> : ""
                                            }
                                        </div>
                                        <div className={styles.UserTextTimeStamp}>
                                                    {formatDate(receivedTime)}
                                            </div>
                                    </div>
                                    
                                </Stack>
                            </Stack.Item>
                            
                        </div>
                    ) : (
                    <Stack.Item grow>
                        <div className={styles.fadeIn}>
                        
                            <div>
                                <p className={styles.answerText3}>
                                    <span className={styles.jsplpromotionalFacts}>
                                        {currentFact.split('').map((char, index) => (
                                            <span
                                                key={index}
                                                className={styles.typingAnimationWord}
                                                style={{ animationDelay: `${index * 0.03}s` }}
                                            >
                                                {char}
                                            </span>
                                        ))}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Stack.Item>
                    )}
                    
                    
                    </Stack.Item>
            </div>
            
           

            

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

        
            
      
        </Stack>
    );
};
