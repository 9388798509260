// import { Stack } from "@fluentui/react";
// import { animated, useSpring } from "@react-spring/web";
// import {Container,Row,Col} from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.css';

// import styles from "./Answer.module.css";
// import { AnswerIcon } from "./AnswerIcon";

// export const AnswerLoading = () => {
//     const animatedStyles = useSpring({
//         from: { opacity: 0 },
//         to: { opacity: 1 }
//     });

    

//     return (
//         <animated.div style={{ ...animatedStyles }}>
//             <div className={styles.classFier}>
//                 <div className={styles.emptylogojaiicon}>
//                     <img src="../../../public/Ask-Jai-Icon.svg" alt="Ask JAI" width="25px;" />
//                 </div>

//                 <Stack className={styles.answerContainer1}verticalAlign="space-between">
//                 <Stack.Item grow>
//                         <p className={styles.answerText1}>
//                             <span className={styles.jsplpromotionalFacts}>Jindal Panther Fe 550D TMT rebars have a higher load-bearing capacity while maintaining the same ductility as per BIS 1786 standards</span>
//                             <span className={styles.loadingdots} />
//                         </p>
//                 </Stack.Item>
//                  {/* <AnswerIcon /> */}
//                 {/* <img src="jspl-creative-1.png" alt="logo" style={{ height:"45px",width:"145px" }} /> */}
                
//             </Stack>
                
                    
//             </div>
            
//         </animated.div>
//     );
// };



import React, { useState, useEffect } from 'react';
import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';

import styles from "./Answer.module.css";

const facts = [
    "Do you know Jindal Panther is India’s 1st and largest producer of FE550D TMT Rebar",
    "Do you know Jindal Panther TMT rebars are produced at our own Integrated Steel Plant.",
    "Jindal Panther is proud to be a partner in India’s growth journey",
    "Asly Sariye ki pehchan Jindal Panther ka Nishan",
    "Desh ki Shaan har Nirman ki Jan, Jindal Panther",
    "Do you know Jindal Panther is a top choice for Contractors, Architects and Consumers"
];

const getRandomFact = () => {
    const randomIndex = Math.floor(Math.random() * facts.length);
    return facts[randomIndex];
};

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    const [currentFact, setCurrentFact] = useState(getRandomFact());

    useEffect(() => {
        const factInterval = setInterval(() => {
            setCurrentFact(getRandomFact());
        }, 4000); // Update fact every 2 seconds

        return () => clearInterval(factInterval); // Cleanup interval on component unmount
    }, []);

    return (
        <animated.div style={{ ...animatedStyles }}>
            <div className={styles.classFier}>
                <div className={styles.emptylogojaiicon}>
                    <img src="../../../public/Ask-Jai-Icon.svg" alt="Ask JAI" width="25px;" />
                </div>

                

                <Stack className={styles.answerContainer1} verticalAlign="space-between">
                    <Stack.Item grow>
                        <div className={styles.fadeIn}>
                        
                            <div>
                                <p className={styles.answerText1}>
                                    <span className={styles.jsplpromotionalFacts}>
                                        {currentFact.split('').map((char, index) => (
                                            <span
                                                key={index}
                                                className={styles.typingAnimationWord}
                                                style={{ animationDelay: `${index * 0.03}s` }}
                                            >
                                                {char}
                                            </span>
                                        ))}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Stack.Item>
                    {/* <AnswerIcon /> */}
                    {/* <img src="jspl-creative-1.png" alt="logo" style={{ height:"45px",width:"145px" }} /> */}
                </Stack>
            </div>
        </animated.div>
    );
};
