import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";

import styles from "./Layout.module.css";
// import CarousolCard from "../../components/carousolCard/carousolCard";



// import Sidevar from "../../components/sidevar/sidevar.lazy";
// import Sidevar from "../../components/sidevar/sidevar";

const Layout = () => {
    return (
        <div className={styles.layout}>
          
            <Outlet />
            
           
            {/* <div className={styles.Sidevar}>
                <Sidevar />
            </div> */}
            
            {/* <footer className={styles.footer}>

                <b>Jai (powered by GPT-4) is experimental and may provide inaccurate or misleading responses. User discretion is advised.</b>
                
            </footer> */}

            
        </div>
    );
};

export default Layout;
